@import "~normalize.css/normalize.css";

// you need this var for font-awesome
$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";
@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
// @import ~@fortawesome/fontawesome-pro-webfonts/scss/fa-regular
// @import ~@fortawesome/fontawesome-pro-webfonts/scss/fa-solid
// Uncomment the above if you need to use different fonts

@import "~magnific-popup/dist/magnific-popup.css";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "fonts";
@import "error";
